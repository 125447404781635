import axios from 'axios'
import {Host} from "@/utils/def";
// 接口调用基准路径
export const baseURL = Host
import router from '../router/index.js';

// 创建一个独立的实例对象
const request = axios.create({
    baseURL: baseURL,
});
// 响应拦截器
request.interceptors.response.use(res => {
    // 把返回的数据去掉一层data属性
    console.log('....', res)
    if (res.data.code == -1000) {
        router.push({ path: '/login' });
    }
    return res.data
}, err => {
    return Promise.reject(err)
})

// 请求拦截器
request.interceptors.request.use(config => {
    // 统一添加请求头
    config.headers.Version = 'v1.01';
    config.headers.SessionID = localStorage.SessionID ? localStorage.SessionID : '';

    return config
}, err => {
    return Promise.reject(err)
})

// 封装通用的接口调用方法
export default (options) => {
    return request({
        method: options.method || 'GET',
        url: options.url,
        // ES6规则：对象的key可以是动态的变量
        [options.method.toUpperCase() === 'GET' ? 'params' : 'data']: options.data
    })
}
