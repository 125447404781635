<template>
  <div>
    <div style="margin: 0 0 10px 0; text-align: right; font-size: 20px; color: red"> 剩余彩信余额53975元</div>
    <div style="display: flex; flex-direction: row">
      <div style="flex: 2">
        <div style="background: white; padding: 10px">
          <div class="flex-row">
            <div style="margin-right: 20px">发送模式</div>
            <el-radio-group v-model="mode">
              <el-radio label="1" size="large">彩信</el-radio>
              <el-radio label="2" size="large">链接格式</el-radio>
            </el-radio-group>
          </div>
          <div class="flex-row">
            <div style="width: 120px">设置发送倒计时</div>
            <el-input v-model="countDown" style="width: 200px">
              <template v-slot:suffix>秒</template>
            </el-input>
            <el-button type="primary" style="margin-left: 10px" @click="countDownSeconds=countDown">保存</el-button>
          </div>
        </div>
        <div class="flex-row" style="background: white; margin-top: 10px; padding: 10px">
          <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              :on-change="handleChange1"
              :auto-upload="false"
          >
            <img v-if="video.length > 0" :src="video" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon">
              <div >
                <Plus />
                <div style="font-size: 20px">上传视频</div>
              </div>
            </el-icon>
          </el-upload>
          <el-upload
              style="margin-left: 10px"
              class="avatar-uploader"
              :show-file-list="false"
              :on-change="handleChange2"
              :auto-upload="false"
          >
            <img v-if="imgUrl.length > 0" :src="imgUrl" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon">
              <div >
                <Plus />
                <div style="font-size: 20px">上传图片</div>
              </div>
            </el-icon>
          </el-upload>
        </div>
        <div style="background: white; margin-top: 10px; padding: 10px">
          <div>
            短信内容
          </div>
          <el-input style="margin-top: 10px" type="textarea" :rows="3" v-model="message" placeholder="编写短信内容"/>
          <el-button type="primary" style="margin-top: 10px">保存</el-button>
        </div>
      </div>
      <div style="flex: 3; padding: 0 0 20px 20px; margin: 0">
        <div v-if="countDownVisible" style="padding: 5px 10px 5px 10px; margin-bottom: 10px; background: #FFB6C1; border: #FF69B4 solid 1px; border-radius: 4px">
          开始倒计时: {{countDownSeconds}}秒
        </div>
        <el-table :data="list" style="width: 100%;" height="360" stripe>
          <el-table-column prop="name" label="名字" />
          <el-table-column prop="phone" label="号码" />
          <el-table-column label="状态">
            <div class="flex-row">
              <img src="@/assets/time.png" width="20"/> <span style="margin-left: 5px">待发送</span>
            </div>
          </el-table-column>
        </el-table>
        <div style="width: 100%; text-align: right; margin-top: 5px">
          总数:{{list.length}}
        </div>
        <div class="flex-row" style="background: white; padding: 10px; margin-top: 5px">
          <el-button v-if="sendState === 0" type="primary" @click="(this.sendState = 3) && sendClick()">{{btnName}}</el-button>
          <el-button v-if="sendState !== 0" type="primary" @click="pauseSend">{{(sendState === 1 || sendState === 3) ? '暂停发送' : '继续发送'}}</el-button>
          <el-button v-if="sendState !== 0" type="primary" @click="cancelSend">取消发送</el-button>
          <div class="flex-column" style="margin-left: 20px;">
            <div class="demo-progress" style="align-self: center;">
              <el-progress :percentage="process" />
            </div>
            <div class="flex-row" style="align-self: start">
              <div>服务器状态:</div>
              <div style="border: #eeeeee solid 1px; border-radius: 2px; color: #90EE90; padding: 0 4px 0 4px; margin-left: 5px;">稳定</div>
            </div>
          </div>
          <div>用户手机: </div>
          <div style="margin-left: 5px">{{phone}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Plus } from '@element-plus/icons-vue'
import {GetContacts} from "@/api";
import {SendTime} from "@/utils/def";
export default {
  name: "groupSendPage",
  components: {
    Plus
  },
  data() {
    return {
      sendState: 0,
      message: '',
      btnName: '开始发送',
      sendTime: 0,
      reset: true,
      mode: '1',
      countDown: '',
      imgUrl: [],
      video: [],
      countDownSeconds: 0,
      list: [],
      userID: -1,
      phone: '',
      countDownVisible: false,
      process: 0,
    }
  },
  mounted() {
    this.sendTime = SendTime
  },
  methods: {
    pauseSend() {
      //如果当前是准备倒计时，变成暂停 || 如果当前是发送中，变成暂停
      if (this.sendState === 3 || this.sendState === 1) {
        this.sendState = 2
      } else if (this.sendState === 2) {
        //如果当前是暂停，判断当前是准备还是发送中
        if (this.countDownVisible) {
          this.sendState = 3
        } else {
          this.sendState = 1
        }
      }
    },
    cancelSend() {
      this.btnName = '开始发送'
      this.reset = true
      this.process = 0
      this.countDownVisible = false
      this.countDownSeconds = this.countDown
      this.sendState = 0
    },
    onShow(userid, phone) {
      this.initData()
      this.userID = userid
      this.phone = phone
      this.btnName = '开始发送'
      this.sendState = 0
      this.reset = true
      this.load()
    },
    initData() {
      this.btnName = '开始发送'
      this.reset = true
      this.imgUrl = []
      this.video = []
      this.list = []
      this.process = 0
      this.countDownVisible = false
      this.countDown = ''
      this.countDownSeconds = this.sendTime
      this.sendState = 0
    },
    sendClick() {
      this.reset = false
      this.countDownVisible = true
      setTimeout(() => {
        if (this.reset) {
          return
        }
        if (this.sendState === 2) {
          this.sendClick()
        } else {
          this.countDownSeconds--
          if (this.countDownSeconds < 0) {
            this.countDownSeconds = 0
            this.sendState = 1
            this.sendStart()
          } else {
            this.sendClick()
          }
        }
      }, 1000)
    },
    sendStart() {
      this.btnName = '发送中'
      let n = 100 / this.sendTime
      setTimeout(() => {
        if (this.reset) {
          return
        }
        if (this.sendState === 2) {
          this.sendStart()
        } else {
          this.process += n
          this.process = parseInt((this.process * 100).toString()) / 100
          if (this.process >= 100) {
            this.process = 100
          } else {
            this.sendStart()
          }
        }
      }, 1000)
    },
    handleChange2(e) {
      console.log(e)
      let that = this
      let reader = new FileReader();
      reader.readAsDataURL(e.raw); // 关键一步，在这里转换的
      reader.onloadend = function () {
        that.imgUrl = this.result;//赋值
      }
    },
    handleChange1(e) {
      console.log(e)
      let that = this
      let reader = new FileReader();
      reader.readAsDataURL(e.raw); // 关键一步，在这里转换的
      reader.onloadend = function () {
        that.video = this.result;//赋值
      }
    },
    load() {
      if (this.userID < 0) {
        return
      }
      GetContacts({
        user_id: this.userID
      }).then(res => {
        this.list = res.data.list
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>

<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.demo-progress .el-progress--line {
  margin-bottom: 5px;
  width: 150px;
}
</style>
