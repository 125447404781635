<template>
  <div>
    <div style="overflow-y:scroll; height: 640px; margin: 0">
      <div class="list">
        <div style="height: 180px; width: 238px; background: black; margin: 10px; align-self: center; display: flex; align-items: center; justify-content: center; flex-direction: row" v-for="(item, index) in list" :key="index">
          <img style="height: 100%;" @click="handlePictureCardPreview(item)" :src="item.url"/>
        </div>
      </div>
    </div>

    <el-button type="primary" style="margin-left: 10px; margin-top: 10px; margin-right: 10px" plain @click="loadMore">加载下一页</el-button>
    <el-dialog v-model="dialogVisible">
      <img w-full style="width: 100%; height: 100%;" :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>
  </div>
</template>

<script>
import {GetPhotos} from "@/api";
import {FileUrl} from "@/utils/def";

export default {
  name: "photoPage",
  props: {
    user_id: {
      defaultValue: -1
    }
  },
  data() {
    return {
      list: [],
      page: 0,
      pageSize: 20,
      userID: -1,
      fileUrl: '',
      dialogImageUrl: "",
      dialogVisible: false
    }
  },
  mounted() {
    this.fileUrl = FileUrl
  },
  methods: {
    onShow(val) {
      this.page = 0
      this.pageSize = 20
      this.userID = val
      this.list = []
      this.load()
    },
    handlePictureCardPreview(uploadFile) {
      this.dialogImageUrl = uploadFile.url
      this.dialogVisible = true
    },
    load() {
      if (this.userID < -1) {
        return
      }
      GetPhotos(
          {user_id: this.userID, page: this.page, page_size: this.pageSize}
      ).then(res => {
        for (let item of res.data.list) {
          this.list.push({
            name: item.id,
            url: this.fileUrl + item.url
          })
        }
        console.log(this.list)
      }).catch(err => {
        console.log(err)
      }).finally(() => {

      })
    },
    loadMore() {
      this.page++
      this.load()
    }
  }
}
</script>

<style scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
</style>