<template>
  <div>
    <el-table :data="list" style="width: 100%; margin-top: 20px" height="500" stripe>
      <el-table-column label="类型" width="80">
        <template #default="scope">
          <el-tag>{{scope.row.fromPhone.length === 0 ? '发送' : '接收'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="短信号码" width="180">
        <template #default="scope">
          {{scope.row.fromPhone.length === 0 ? scope.row.toPhone : scope.row.fromPhone}}
        </template>
      </el-table-column>
      <el-table-column prop="message" label="短信内容" />
      <el-table-column label="短信发送时间" width="200">
        <template #default="scope">
          {{$dateFormat(scope.row.ts/1000000)}}
        </template>
      </el-table-column>
    </el-table>
<!--    <div style="margin-top: 20px; height: 20px" >
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="pageChange"/>
    </div>-->
  </div>
</template>

<script>
import {GetMessages} from "@/api";

export default {
  name: "messagePage",
  props: {
    user_id: {
      defaultValue: -1
    }
  },
  data() {
    return {
      total: 0,
      pageSize: 10,
      page: 0,
      list: [],
      userID: -1,
    }
  },
  mounted() {
  },
  methods: {
    pageChange(index) {
      this.page = index - 1
      this.load()
    },
    onShow(val) {
      this.list = []
      this.userID = val
      this.list = []
      this.load()
    },
    load() {
      if (this.userID < -1) {
        return
      }
      GetMessages({
        page: this.page,
        page_size: this.pageSize,
        user_id: this.userID
      }).then(res => {
        this.list = res.data.list
        this.total = res.data.total
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
